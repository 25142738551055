
currenApp.factory('httpResponseInterceptor', function ($q, $location,$injector) {
	 return {
	 	
        'responseError': function(rejection) {
        	console.log("intercept",rejection) 
            if(rejection.status === 501 && rejection.data.error=="invalid_token"){

            	var $http = $injector.get('$http');
                var deferred = $q.defer();
                var tokenService = $injector.get('tokenService');
            

            	console.log(deferred.resolve)
            	tokenService.refreshToken().then(deferred.resolve,deferred.reject);
			    

			    return deferred.promise.then(function(response) {
			    	csrfToken=response.data;
			    	rejection.config.data._token=csrfToken;
			    	return $http(rejection.config);
                });

                //alert(baseUrl);        
            }
           
            return $q.reject(rejection);
         }
     };

});